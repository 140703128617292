:root {
    /*project subtitle*/
    --project-subtitle-mobile: 1.1625rem;
    --project-subtitle: 1.375rem;
    --project-subtitle-xl: 1.8375rem;
    --project-subtitle-margin-bottom: 0.4375rem;
    --project-subtitle-margin-bottom-xl: 0.8rem;
    /*project title*/
    --project-title-mobile: 1.625rem;
    --project-title: 2.25rem;
    --project-title-xl: 3rem;
    /*project description*/
    --project-description-mobile: 0.9375rem;
    --project-description: 0.9375rem;
    --project-description-xl: 1.5rem;
    --project-description-line-height: 1.375rem;
    --project-description-line-height-xl: 2.25rem;
    --project-description-margin-top-mobile: 1.25rem;
    --project-description-margin-top: 2rem;
    --project-description-margin-top-xl: 2.5rem;
    /*project tags*/
    --project-tags-mobile: 0.625rem;
    --project-tags: 0.625rem;
    --project-tags-xl: 0.875rem;
    --project-tag-horizontal-spacing: 1.5rem;
    --project-tag-horizontal-spacing-xl: 3.5rem;
    --project-tag-vertical-spacing: 1rem;
    --project-tag-vertical-spacing-xl: 1.75rem;
}

.prose {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: var(--header-half-content-max-width);
}

.project-subtitle {
    text-align: left;
    font-size: var(--project-subtitle);
    font-weight: 400;
    margin-bottom: var(--project-subtitle-margin-bottom);
}

.project-title {
    text-align: left;
    font-size: var(--project-title);
    font-weight: 600;
}

.project-description {
    text-align: left;
    font-size: var(--project-description);
    font-weight: 400;
    white-space: pre-wrap;
    margin-top: var(--project-description-margin-top);
    line-height: var(--project-description-line-height);
}

.project-tags {
    color: #aaaaaa;
    font-size: var(--project-tags);
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.16em;
    text-align: left;
    margin-top: 3rem;
}

.project-tag {
    display: inline-block;
    margin-bottom: var(--project-tag-vertical-spacing);
    margin-right: var(--project-tag-horizontal-spacing);
}

.project-tag:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 1080px) {
    .prose {
        max-width: var(--header-half-content-max-width-mobile);
    }
    
    .project-subtitle {
        font-size: var(--project-subtitle-mobile);
    }
    .project-title {
        font-size: var(--project-title-mobile);
    }
    .project-description {
        font-size: var(--project-description-mobile);
        margin-top: var(--project-description-margin-top-mobile);
    }
    .project-tags {
        margin-top: 2rem;
    }
}

@media only screen and (min-width: 2250px) and (min-height: 1080px) {
    .prose {
        max-width: var(--header-half-content-max-width-xl);
    }
    .project-subtitle {
        margin-bottom: var(--project-subtitle-margin-bottom-xl);
        font-size: var(--project-subtitle-xl);
    }
    .project-title {
        font-size: var(--project-title-xl);
    }
    .project-description {
        font-size: var(--project-description-xl);
        line-height: var(--project-description-line-height-xl);
        margin-top: var(--project-description-margin-top-xl);
    }
    .project-tags {
        font-size: var(--project-tags-xl);
    }
    .project-tag {
        margin-bottom: var(--project-tag-vertical-spacing-xl);
        margin-right: var(--project-tag-horizontal-spacing-xl);
    }
}