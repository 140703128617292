:root {
    --project-copy: #F8FAFB;
    --overlay-transition-open: 0.2s;
    --overlay-transition-close: 0.16s;
    --overlay-interactive-primary: #A08A5D;
    --overlay-interactive-secondary: #84a1c4;
    --overlay-interactive-secondary-hover: #ffffff;
    /*overlay close*/
    --overlay-close-icon-offset-mobile: 2rem;
    --overlay-close-icon-offset: 3.5rem;
    --overlay-close-icon-offset-xl: 6rem;
    /*overlay padding*/
    --overlay-inner-padding-mobile: 2rem;
    --overlay-outer-padding-mobile: 2rem;
    --overlay-inner-padding: 4.5rem;
    --overlay-outer-padding: 3rem;
    --overlay-inner-padding-xl: 4.5rem;
    --overlay-outer-padding-xl: 5.5rem;
}

/* TRANSITION ANIMATIONS */

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

a {
    color: var(--overlay-interactive-secondary);
    text-decoration: none;
    transition: color .15s;
}

a:hover {
    color: var(--overlay-interactive-secondary-hover);
    transition: color .15s;
}

p:last-child {
    margin-bottom: 0;
}

.project-external-link-icon {
    width: 0.8rem !important;
    height: 0.8rem !important;
}

.overlay {
    color: var(--project-copy);
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.96);
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, ease var(--overlay-transition-close), opacity var(--overlay-transition-close) ease;
    flex-direction: column;
    /* Scrollbar */
    scroll-behavior: smooth;
    scrollbar-width: thin;
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}

.overlay::-webkit-scrollbar {
    /* WebKit (Chrome) */
    width: 0;
    height: 0;
}

.overlay.open {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, ease var(--overlay-transition-open), opacity var(--overlay-transition-open) ease;
}

.overlay-content {
    padding: var(--overlay-outer-padding) var(--overlay-outer-padding) 0 var(--overlay-outer-padding);
    display: flex;
    flex-direction: row;
    opacity: 1;
    animation: fadeIn ease-in 1;
    animation-duration: 0.16s;
    flex: 1;
}

.overlay-content.transparent {
    opacity: 0;
    animation: fadeOut ease-in 1;
    animation-duration: 0.1s;
}

.overlay-content-prose {
    padding: var(--overlay-inner-padding) var(--overlay-inner-padding) 0 var(--overlay-inner-padding);
    flex: 15;
    order: 1;
}

.overlay-content-gallery {
    padding: var(--overlay-inner-padding) var(--overlay-inner-padding) 0 var(--overlay-inner-padding);
    flex: 14;
    order: 2;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1200px) {
    .overlay-content {
        padding: var(--overlay-outer-padding-mobile) var(--overlay-outer-padding-mobile) 0 var(--overlay-outer-padding-mobile);
    }
    /* Monitor this if issue arises */
    .overlay-content-gallery {
        justify-content: start;
        height: auto;
    }
}

@media only screen and (max-width: 1080px) {
    .overlay {
        display: block;
    }
    .overlay-content {
        flex-direction: column;
        padding: 0;
    }
    .overlay-content-prose {
        order: 2;
        padding: var(--overlay-inner-padding-mobile) var(--overlay-inner-padding-mobile) 0 var(--overlay-inner-padding-mobile);
        margin-top: -0.5rem;
    }
    .overlay-content-gallery {
        order: 1;
        padding: 0;
    }
}

@media only screen and (min-width: 2600px) {
    .overlay-content-prose {
        display: flex;
        justify-content: center;
    }
    .overlay-content-gallery {
        display: flex;
    }
}